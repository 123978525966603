import React, { createContext, useState, useEffect } from 'react';
import AuthRouting from './pages/AuthRouting';
import PublicRouting from './pages/PublicRouting';
import { isAccessTokenValid } from './utils/cookies';
export const UserContext = createContext();

function App() {
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(isAccessTokenValid());
    setInitialLoad(false);
  }, []);

  if (initialLoad) {
    // TODO: replace with a nice loading animation
    return null;
  }

  return (
    <div className="App">
      <UserContext.Provider value={[isLoggedIn, setIsLoggedIn]}>
        {isLoggedIn ? <AuthRouting /> : <PublicRouting />}
      </UserContext.Provider>
    </div>
  );
}

export default App;
