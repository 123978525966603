import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import './Dashboard.css';
import {
  HomeFilled,
  RiseOutlined,
  QrcodeOutlined,
  SettingFilled,
} from '@ant-design/icons';

const { Sider } = Layout;

export default function SideNavigation() {
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="80"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <div className="logo" />
      <Menu theme="dark" mode="inline" selectedKeys={[pathname]}>
        <Menu.Item
          key="/dashboard"
          icon={<HomeFilled />}
          onClick={() => {
            history.push('/dashboard');
          }}
        >
          Home
        </Menu.Item>
        <Menu.Item
          key="/analytics"
          icon={<RiseOutlined />}
          onClick={() => {
            history.push('/analytics');
          }}
        >
          Analytics
        </Menu.Item>
        <Menu.Item
          key="/qr"
          icon={<QrcodeOutlined />}
          onClick={() => {
            history.push('/qr');
          }}
        >
          QR Export
        </Menu.Item>
        <Menu.Item
          key="/settings"
          icon={<SettingFilled />}
          onClick={() => {
            history.push('/settings');
          }}
        >
          Settings
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
