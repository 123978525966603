import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Menu from './Menu.js';
import ComingSoon from './ComingSoon';
import InterestForm from './InterestForm';

function PublicRouting() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <ComingSoon />
          </Route>
          {/* <Route path="/login">
            <Login />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route> */}
          <Route path="/interest">
            <InterestForm />
          </Route>
          <Route path="/*">
            <Menu />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default PublicRouting;
