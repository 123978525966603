import React from 'react';
// import { Grid } from '@material-ui/core';

import DashboardContainer from '../../components/DashboardContainer';
import { useQuery } from '@apollo/react-hooks';
import { GET_MENU_URL } from '../../graphql/query';

function QR() {
  const { data, loading } = useQuery(GET_MENU_URL); // eslint-disable-line no-unused-vars
  if (loading) {
    return null;
  }

  return <DashboardContainer></DashboardContainer>;
}

export default QR;
