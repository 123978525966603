import React from 'react';
import './ComingSoon.css';
const ComingSoon = () => (
  <div className="main">
    <h1 className="animate__animated animate__fadeIn">
      launching soon
      <span className="animate__animated animate__flash animate__slow animate__infinite">
        .
      </span>
    </h1>
  </div>
);

export default ComingSoon;
