/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
import React, { useState } from 'react';
import './ClientSide.css';
import { useLocation, Redirect } from 'react-router-dom';
import { GET_BUSINESS_BY_PATHNAME } from '../graphql/query';
import { useQuery } from '@apollo/react-hooks';
import PdfDisplay from '../components/PdfDisplay';
import { Typography, Button } from 'antd';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';

import pixelLogo from '../styles/pixelLogoBlue.png';

const { Paragraph, Title } = Typography;

const MenuButton = ({
  url,
  title,
  setFocusedMenuUrl,
  setMenuTitle,
  icon = (
    <RestaurantIcon
      style={{
        border: '1px solid rgba(0, 0, 0, 0.25)',
        fontSize: 35,
        borderRadius: 17.5,
        padding: 5,
      }}
    />
  ),
}) => {
  if (['drink', 'cocktail'].some((v) => title.toLowerCase().includes(v))) {
    icon = (
      <LocalDrinkIcon
        style={{
          border: '1px solid rgba(0, 0, 0, 0.25)',
          fontSize: 35,
          borderRadius: 17.5,
          padding: 5,
        }}
      />
    );
  }
  return (
    <div
      onClick={() => (setFocusedMenuUrl(url), setMenuTitle(title))}
      className="menuButton"
    >
      <div className="menuIconContainer">{icon}</div>
      <div className="menuInfo">
        <Title id="menuButtonTitle">{title}</Title>
      </div>
    </div>
  );
};

const Menu = () => {
  const pathname = useLocation().pathname;
  const [focusedMenuUrl, setFocusedMenuUrl] = useState('');
  const [menuTitle, setMenuTitle] = useState('');

  const { data, error, loading } = useQuery(GET_BUSINESS_BY_PATHNAME, {
    variables: { pathname },
  });

  if (error || pathname === 'dashboard') {
    return <Redirect to="/" />;
  }

  if (loading) {
    return null;
  }

  const { menus, businessId, name } = data.getBusinessByPathname;

  if (!focusedMenuUrl) {
    if (menus.length === 1) {
      setFocusedMenuUrl(menus[0].url);
    } else {
      return (
        <div id="menuList">
          <div>
            <Title level={3} className="restaurantTitle">
              {name}
            </Title>

            <div className="menuList">
              {menus.map(({ name, url }) => {
                return (
                  <MenuButton
                    key={name}
                    url={url}
                    title={name}
                    setFocusedMenuUrl={setFocusedMenuUrl}
                    setMenuTitle={setMenuTitle}
                  />
                );
              })}
            </div>
          </div>
          <img src={pixelLogo} className="pixelLogo" />
        </div>
      );
    }
  }

  return (
    <div>
      {menus.length > 1 && (
        <div className="menuTitle">
          <div onClick={() => setFocusedMenuUrl('')} className="backIcon">
            <KeyboardBackspaceIcon style={{ color: 'black' }} />
          </div>

          <Title level={4} className="menuName">
            {menuTitle}
          </Title>
        </div>
      )}
      <div className="MenuContainer">
        <PdfDisplay menuUrl={focusedMenuUrl} />
      </div>

      {focusedMenuUrl && (
        <div className="pixelBtn1">
          <Paragraph>
            Trouble viewing?{' '}
            <a href={focusedMenuUrl} target="_blank" rel="noopener noreferrer">
              click here
            </a>
          </Paragraph>
        </div>
      )}
      <div className="pixelBtn">
        <Button
          id="pixelBtn"
          href="https://pixel.menu/"
          target="_blank"
          rel="noreferrer"
        >
          Service by Pixel Menu
        </Button>
      </div>
    </div>
  );
};

export default Menu;
