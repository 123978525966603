import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Icon, InlineIcon } from '@iconify/react';
import qrCodeOutline from '@iconify/icons-ion/qr-code-outline';
import analyticsSharp from '@iconify/icons-ion/analytics-sharp';

import linkIcon from '@iconify/icons-ion/link';
import { useQuery } from '@apollo/react-hooks';
import { GET_BUSINESS_PATHNAME } from '../graphql/query';

const useStyles = makeStyles({
  actions: { paddingRight: '2vw' },
  rotate: {
    transform: 'rotate(135deg) translate(0, 0.25em)',
    overflow: 'hidden',
  },
  lowercase: {
    textTransform: 'none',
  },
  grid: {
    padding: '20px',
  },
  link: {
    textDecoration: 'none',
  },
  btn: { margin: '2vh 0px 2vh 0px' },
});

function ButtonLabel(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Link to={props.path} className={classes.link}>
        <Button
          variant="contained"
          startIcon={props.icon}
          className={classes.btn}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.grid}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                spacing={0}
              >
                <Grid item>
                  <Typography variant="h6" className={classes.title}>
                    {props.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption" className={classes.lowercase}>
                    {props.caption}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Button>
      </Link>
    </Grid>
  );
}

export default function DashboardActions() {
  const classes = useStyles();
  let { loading, error, data } = useQuery(GET_BUSINESS_PATHNAME);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      alignItems="center"
      className={classes.actions}
    >
      <ButtonLabel
        icon={
          <div className={classes.rotate}>
            <InlineIcon icon={linkIcon} width="2em" />
          </div>
        }
        title="View Live Menu"
        caption={`pixel.menu${data.getBusiness.pathname}`}
        path={data.getBusiness.pathname}
      />
      <ButtonLabel
        icon={<Icon icon={qrCodeOutline} width="2em" />}
        title="Export QR Code"
        path="/qr"
      />
      <ButtonLabel
        icon={<Icon icon={analyticsSharp} width="2em" />}
        title="View Analytics"
        path="/analytics"
      />
    </Grid>
  );
}
