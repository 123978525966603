import React from 'react';

export default function InterestForm() {
  return (
    <iframe
      id="typeform-full"
      width="100%"
      height="100%"
      frameBorder="0"
      allow="camera; microphone; autoplay; encrypted-media;"
      src="https://form.typeform.com/to/WVIMf56K"
      style={{
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        position: 'absolute',
      }}
    />
  );
}
