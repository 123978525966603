import gql from 'graphql-tag';

export const REGISTER = gql`
  mutation REGISTER(
    $businessName: String!
    $email: String!
    $password: String!
  ) {
    register(
      input: { businessName: $businessName, email: $email, password: $password }
    ) {
      accessToken
      refreshToken
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      accessToken
      refreshToken
    }
  }
`;

export const REFRESH = gql`
  mutation Refresh($refreshToken: String!) {
    refresh(input: { refreshToken: $refreshToken }) {
      accessToken
      refreshToken
    }
  }
`;
