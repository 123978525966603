// TODO @wdibi: Needs major refactoring
import React from 'react';
import { Spring, config } from 'react-spring/renderprops';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const ItemTitle = styled.p`
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #4c5d77;
`;

const ItemValue = styled.p`
  font-family: Merriweather;
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: -0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

const Item = ({ children, title, color }) => {
  return (
    <div>
      <ItemValue style={{ color }}>{children}</ItemValue>
      <ItemTitle>{title}</ItemTitle>
    </div>
  );
};

const Stats = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Spring
            from={{ number: 0 }}
            to={{ number: 1300 }}
            config={config.slow}
          >
            {(props) => (
              <Item title="Resturants" color="#FF9900">
                {Math.round(props.number)}
              </Item>
            )}
          </Spring>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Spring
            from={{ number: 12000 }}
            to={{ number: 123346 }}
            config={config.slow}
          >
            {(props) => (
              <Item title="Menu Views" color="#7737FF">
                {Math.round(props.number)}
              </Item>
            )}
          </Spring>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Spring
            from={{ number: 33333 }}
            to={{ number: 45673 }}
            config={config.slow}
          >
            {(props) => (
              <Item title="DAU" color="#FB5392">
                {Math.round(props.number)}
              </Item>
            )}
          </Spring>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Spring
            from={{ number: 0 }}
            to={{ number: 123346 }}
            config={config.slow}
          >
            {(props) => (
              <Item title="Positive Reviews" color="#69C85A">
                {Math.round(props.number)}
              </Item>
            )}
          </Spring>
        </Grid>
      </Grid>
    </div>
  );
};

export default Stats;
