import React from 'react';
import PdfDisplay from '../../components/PdfDisplay';
import DashboardActions from '../../components/DashboardActions';
import DashboardContainer from '../../components/DashboardContainer';
import { useQuery } from '@apollo/react-hooks';
import { GET_MENU_URL } from '../../graphql/query';
import { PdfDropzone } from '../../components/FileDropzone/PdfDropzone';
import { Row, Col } from 'antd';

function Dashboard() {
  const { data, loading } = useQuery(GET_MENU_URL); // eslint-disable-line no-unused-vars
  if (loading) {
    return null;
  }

  return (
    <DashboardContainer>
      <Row>
        <Col className="col">
          {/* TODO: Fix size so it is not massive */}
          {data ? <PdfDisplay menuUrl={data.getBusiness.menuUrl} /> : ''}
        </Col>
        <Col className="col2">
          <DashboardActions />
          <PdfDropzone />
        </Col>
      </Row>
    </DashboardContainer>
  );
}

export default Dashboard;
