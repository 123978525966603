import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import './PdfDisplay.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfDisplay({ menuUrl }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [width, setWidth] = useState(Math.min(window.innerWidth * 0.95, 1000));

  useEffect(() => {
    function handleResize() {
      setWidth(Math.min(window.screen.width * 0.95, 1000));
    }
    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (menuUrl === '') {
    return <p>This restaurant has not uploaded a menu yet.</p>;
  }
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const prevPage = () => {
    pageNum > 1 && setPageNum(pageNum - 1);
  };

  const nextPage = () => {
    pageNum < numPages && setPageNum(pageNum + 1);
  };

  return (
    <div style={{ marginBottom: 0 }}>
      <Document
        file={menuUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        className="Document"
      >
        <Page width={width} pageNumber={pageNum} renderTextLayer={false} />
        <div className="Left" onClick={prevPage}>
          <LeftOutlined style={{ fontSize: 30 }} />
        </div>
        <div className="Right" onClick={nextPage}>
          <RightOutlined style={{ fontSize: 30 }} />
        </div>
      </Document>
      <div className="PageInfo">
        <p>
          {pageNum} of {numPages}
        </p>
      </div>
    </div>
  );
}
