import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Dashboard from './Dashboard/Dashboard';
import Menu from './Menu';
import Home from './Home/Home';
import Upload from './Dashboard/DashboardUpload';
import QR from './Dashboard/DashboardQR';
import Analytics from './Dashboard/DashboardAnalytics';
import Settings from './Dashboard/DashboardSettings';

function AuthRouting() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/qr">
            <QR />
          </Route>
          <Route path="/analytics">
            <Analytics />
          </Route>
          <Route path="/upload">
            <Upload />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          {/* TODO: replace demo with restaurant name */}
          <Route path="/demo">
            <Menu />
          </Route>
          <Route path="/*">
            <Menu />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default AuthRouting;
