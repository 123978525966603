import React from 'react';
import { PdfDropzone } from '../../components/FileDropzone/PdfDropzone';

import { Grid } from '@material-ui/core';
import DashboardContainer from '../../components/DashboardContainer';

const onUpload = (file) => console.log(file.name);

// If PDF is dropped outside of div, need to prevent default action
window.addEventListener(
  'dragover',
  function (e) {
    e.preventDefault();
  },
  false
);
window.addEventListener(
  'drop',
  function (e) {
    e.preventDefault();
  },
  false
);

export default function PdfUpload() {
  return (
    <DashboardContainer>
      <Grid item>
        <PdfDropzone onUpload={onUpload} />
      </Grid>
      <Grid item />
    </DashboardContainer>
  );
}
