import Cookies from 'js-cookie';
import { decode } from 'jsonwebtoken';

export const removeAllCookies = () => {
  Cookies.remove('accessToken');
  Cookies.remove('refreshToken');
};

export const removeAndAddAuthCookies = (accessToken, refreshToken) => {
  // TODO: add cookie attributes
  removeAllCookies();
  Cookies.set('accessToken', accessToken);
  Cookies.set('refreshToken', refreshToken);
};

export const setAccessToken = (accessToken) => {
  Cookies.set('accessToken', accessToken);
};

export const getAccessToken = () => {
  return Cookies.get('accessToken');
};

export const getRefreshToken = () => {
  return Cookies.get('refreshToken');
};

export const isAccessTokenValid = () => {
  const accessToken = getAccessToken();
  if (!accessToken) return false;
  const { exp } = decode(getAccessToken());
  return new Date() > exp;
};
