import React from 'react';
import './Dashboard.css';
import SideNav from './SideNavigation';
import ProfileDropDown from './ProfileDropDown';
import { Layout } from 'antd';

const { Header, Content, Footer } = Layout;

const DashboardContainer = ({ children, transparent = false }) => (
  <Layout>
    <SideNav />
    <Layout>
      <Header
        className="site-layout-sub-header-background"
        style={{ padding: 0 }}
      >
        <ProfileDropDown className="dropdown" />
      </Header>
      <Content style={{ margin: '24px 16px 0' }}>
        <div
          className="site-layout-background"
          style={{
            padding: 24,
            minHeight: '85vh',
            backgroundColor: transparent ? 'transparent' : null,
          }}
        >
          {children}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Pixel Menu</Footer>
    </Layout>
  </Layout>
);

export default DashboardContainer;
