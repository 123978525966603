import React, { useContext, useState } from 'react';

import DashboardContainer from '../../components/DashboardContainer';
import { Link } from 'react-router-dom';
import { removeAllCookies } from '../../utils/cookies';
import { UserContext } from '../../App';
import { Layout, Descriptions, Menu, Input, Button, Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { GET_BUSINESS_PATHNAME } from '../../graphql/query';
const { Content, Sider } = Layout;

const BasicSettings = () => {
  const { data, loading } = useQuery(GET_BUSINESS_PATHNAME);
  if (loading || !data) return <Spin />;
  return (
    <div>
      <div style={{ width: '45%', marginBottom: 16 }}>
        <Input
          addonBefore="pixel.menu/"
          defaultValue={data.getBusiness.pathname.substring(1)}
          disabled
        />
      </div>
      <Descriptions title="Company Info">
        <Descriptions.Item label="Representative">John Smith</Descriptions.Item>
        <Descriptions.Item label="Email">apple@gmail.com</Descriptions.Item>
        <Descriptions.Item label="Phone">xxx-yyy-5801</Descriptions.Item>
        <Descriptions.Item label="Address" span={2}>
          1 Loyola Marymount University Dr
          <br />
          Los Angeles, CA
          <br />
          90045
          <br />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};
function DashboardSettings() {
  const [_, setIsLoggedIn] = useContext(UserContext);
  const [menuKey, setMenuKey] = useState('1');
  const handleMenuItemClick = ({ key }) => setMenuKey(key);
  return (
    <DashboardContainer justify="space-between" transparent>
      <Layout>
        <Content style={{ padding: '0 50px' }}>
          <Layout
            className="site-layout-background"
            style={{ padding: '24px 0' }}
          >
            <Sider className="site-layout-background" width={200}>
              <Menu
                mode="inline"
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                style={{ height: '100%' }}
              >
                <Menu.Item key="1" onClick={handleMenuItemClick}>
                  Basic
                </Menu.Item>
                <Menu.Item key="2" onClick={handleMenuItemClick}>
                  Billing
                </Menu.Item>
              </Menu>
            </Sider>
            <Content style={{ padding: '0 24px', minHeight: 280 }}>
              {menuKey === '1' ? <BasicSettings /> : <div></div>}
            </Content>
          </Layout>
        </Content>
      </Layout>
      <Link to="/">
        <Button
          variant="contained"
          onClick={() => {
            removeAllCookies();
            setIsLoggedIn(false);
          }}
        >
          log out
        </Button>
      </Link>
    </DashboardContainer>
  );
}

export default DashboardSettings;
