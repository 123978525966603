/* eslint react/display-name: 0 */
import React from 'react';
import { Chart, LineAdvance, Area } from 'bizcharts';

import DashboardContainer from '../../components/DashboardContainer';
import { Card, Col, Row, Divider, Table, Tag } from 'antd';
import Faker from 'faker';
function Analytics() {
  const fakeData = () =>
    [
      '8a',
      '9a',
      '10a',
      '11a',
      '12p',
      '1p',
      '2p',
      '3p',
      '4p',
      '5p',
      '6p',
      '7p',
      '8p',
      '9p',
      '10p',
    ].map((time) => ({
      type: 'Today',
      time,
      scans: Math.floor(Math.random() * 250) + 100,
    }));

  const scale = {
    scans: {
      min: 0,
      nice: true,
    },
  };

  let fakeScanTableData = [];

  for (let i = 0; i < 500; i++) {
    console.log(Faker.internet.userAgent());
    fakeScanTableData.push({
      key: i.toString(),
      platform: 'iOS',
      time: Faker.date.past(),
      tags: ['One site', 'Returning client'],
    });
  }
  const columns = [
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (time) => <span>{new Date(time).toLocaleTimeString()}</span>,
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: (key) => <span>{key}</span>,
    },
    {
      title: 'Tags',
      key: 'tags',
      dataIndex: 'tags',
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = tag === 'One site' ? 'geekblue' : 'green';
            if (tag === 'Returning client') {
              color = 'cyan';
            } else if (tag === 'New client') {
              color = 'orange';
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];
  return (
    <DashboardContainer transparent>
      <div className="site-card-wrapper">
        <Row gutter={16}>
          <Col span={4}>
            <Card bordered={false}>
              <span style={{ color: 'rgba(0,0,0,.45)' }}>Total Menu Views</span>
              <h2>8,300</h2>
              <Chart height={30} data={fakeData()} scale={scale} autoFit pure>
                <Area shape="smooth" position="time*scans" />
              </Chart>
              <Divider />
              <h4>
                <span style={{ color: 'rgba(0,0,0,.45)' }}>Daily Avg.</span> 397
              </h4>
            </Card>
          </Col>
        </Row>
      </div>
      <div
        style={{
          backgroundColor: 'white',
          marginTop: 16,
          padding: 20,
        }}
      >
        <Chart
          padding={[10, 20, 50, 40]}
          autoFit
          height={300}
          data={fakeData()}
        >
          <LineAdvance
            shape="smooth"
            point
            area
            position="time*scans"
            color="type"
          />
        </Chart>
      </div>
      <div
        style={{
          backgroundColor: 'white',
          width: '50%',
          marginTop: 16,
          padding: 20,
        }}
      >
        <Table
          size="small"
          showHeader={false}
          columns={columns}
          dataSource={fakeScanTableData}
          pagination={{ pageSize: 15, showSizeChanger: false }}
        />
      </div>
    </DashboardContainer>
  );
}

export default Analytics;
