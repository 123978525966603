import React, { useContext } from 'react';
import { UserContext } from '../App';
import { removeAllCookies } from '../utils/cookies';
import { useQuery } from '@apollo/react-hooks';
import { GET_BUSINESS_NAME } from '../graphql/query';

import { Menu, Dropdown, Button, PageHeader } from 'antd';
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';

function DropDownElements() {
  const [_, setIsLoggedIn] = useContext(UserContext); //eslint-disable-line no-unused-vars

  const handleLogout = () => {
    removeAllCookies();
    setIsLoggedIn(false);
  };

  return (
    <Menu>
      <Menu.Item key="1" icon={<SettingOutlined />}>
        <a href="/settings">Settings</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );
}

const DropdownMenu = () => {
  const { data } = useQuery(GET_BUSINESS_NAME);

  return (
    <Dropdown key="more" overlay={<DropDownElements />}>
      <Button
        style={{
          border: 'none',
          padding: 0,
        }}
      >
        {data && (
          <div>
            {/* {data.getBusiness.name.toUpperCase()} */}
            <UserOutlined
              style={{
                marginLeft: 5,
                fontSize: 20,
                verticalAlign: 'top',
              }}
            />
          </div>
        )}
      </Button>
    </Dropdown>
  );
};

export default function ProfileDropDown() {
  const { data } = useQuery(GET_BUSINESS_NAME);

  return (
    <PageHeader
      className="site-page-header"
      title={data ? data.getBusiness.name.toUpperCase() : ''}
      extra={[<DropdownMenu key="more" />]}
    />
  );
}
