import React from 'react';
import ReactDOM from 'react-dom';
import 'fontsource-roboto';
import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-client';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloLink, from, execute, makePromise } from 'apollo-link';
import Cookies from 'js-cookie';
import { REFRESH } from './graphql/mutation';
import { setAccessToken } from './utils/cookies';

const cache = new InMemoryCache();
// eslint-disable-next-line
// Hardcode to prod since railway.app env vars aren't working
const http = new HttpLink({ uri: "https://api.pixel.menu/graphql" });

const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message }) =>
        console.log(`[GraphQL error]: Message: ${message}`)
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }),
  setContext(({ headers }) => {
    const accessToken = Cookies.get('accessToken');
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  }),
  // TODO: Implement prod and stg api switch based off .env
  http,
]);

const refreshMiddleware = new ApolloLink((operation, forward) => {
  const refreshToken = Cookies.get('refreshToken');
  const oo = {
    query: REFRESH,
    variables: { refreshToken },
  };

  if (refreshToken) {
    makePromise(execute(http, oo))
      .then((resp) => {
        const newAccessToken = resp.data.refresh.accessToken;
        setAccessToken(newAccessToken);
      })
      .catch((error) => console.log(`received error ${error}`));
  }

  return forward(operation);
});

const client = new ApolloClient({
  link: from([refreshMiddleware, link]),
  cache,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.unregister();
