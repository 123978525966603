import React from 'react';
import { useFileDrop } from './DropzoneHooks';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const props = {
  name: 'file',
  accept: '.pdf',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

export const PdfDropzone = ({ onUpload }) => {
  /* eslint-disable */
  const { Dropzone, Input, onClick, drag, files } = useFileDrop(onUpload);

  const handleUpload = () => {
    alert(`${files[0].name} successfully uploaded`);
  };
  /* eslint-enable */

  return (
    <div className="dropContainer">
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single file pdf upload.</p>
      </Dragger>
    </div>
  );
};
