import React, { useRef, useState } from 'react';

export const useFileDrop = (onUpload) => {
  const ref = useRef(null);
  const [drag, setDrag] = useState(false);
  const [files, setFiles] = useState([]);

  const onDragOver = (val) => (e) => {
    e.preventDefault();
    setDrag(val);
  };

  const onDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const files = e.dataTransfer.files;
    const isPDF = files[0].type === 'application/pdf';

    if (files && files.length > 0) {
      if (isPDF) {
        setFiles([...files, files[0]]);
        onUpload(files[0]);
      } else
        alert('pixel.menu is currently only compatable with pdf file types');
    }
    setDrag(false);
  };

  const onChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setFiles([...files, files[0]]);
      onUpload(files[0]);
    }
  };

  const onClick = () => {
    console.log(ref);
    ref.current.click();
  };

  const Input = () => (
    <input
      type="file"
      style={{ display: 'none' }}
      accept=".pdf"
      onChange={onChange}
      ref={ref}
    />
  );

  const Dropzone = (props) => (
    <div
      onDragEnter={onDragOver(true)}
      onDragLeave={onDragOver(false)}
      onDragOver={onDragOver(true)}
      onDrop={onDrop}
      {...props}
    />
  );

  return {
    Dropzone,
    Input,
    onClick,
    drag,
    files,
  };
};
