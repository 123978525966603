import React, { useContext } from 'react';
import { UserContext } from '../../App';
import { Layout, Menu } from 'antd';
import { Main } from './components/Sections';
const { Header, Content } = Layout;

function Home() {
  const [isLoggedIn] = useContext(UserContext); //eslint-disable-line no-unused-vars
  // {isLoggedIn ? <NavLoggedIn /> : <NavDefault />}

  return (
    <Layout className="layout">
      <Header style={{ backgroundColor: '#fff' }}>
        <Menu theme="light" mode="horizontal">
          <Menu.Item key="how-it-works">How it Works</Menu.Item>
          <Menu.Item key="pricing">Pricing </Menu.Item>
          <Menu.Item key="faq">FAQ</Menu.Item>
          <Menu.Item key="sign-in">Sign in</Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <Main />
      </Content>
    </Layout>
  );
}
export default Home;
