import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button as BB } from 'antd';
import './Main.css';

const CardContents = () => {
  return (
    <div>
      <div className="bullets">
        <ul>
          <li>Contactless menus easily accessible via QR code</li>
          <li>Unlimited menu uploads and modification</li>
          <li>Free QR codes to download or print</li>
          <li>Email & Chat support</li>
        </ul>
      </div>
      <BB type="primary" block style={{ borderRadius: 10 }}>
        <div>
          Get Started
          <div style={{ float: 'right' }}>
            <ArrowRightOutlined />
          </div>
        </div>
      </BB>
    </div>
  );
};

const SideCard = () => {
  return (
    <div
      className="animate__animated animate__fadeInRight"
      style={{
        maxWidth: 550,
        minWidth: 500,
        padding: 20,
        borderRadius: 10,
        backgroundColor: '#FFFFFF',
      }}
    >
      <h4>Create a free account today!</h4>
      <CardContents />
    </div>
  );
};

const Main = () => {
  const isSingleCol = useMediaQuery({ query: '(max-width: 1200px)' });
  return (
    <div className="main">
      <div className="card">
        <h1>Mobile Menus Made Easy</h1>
        <h3>
          Pixel Menu is a free service offering restauranters the opportunity to
          create easily accessible digital menus. Create your business profile
          today and receive your unique QR code.
        </h3>
      </div>
      <div className="card">
        {isSingleCol ? <CardContents /> : <SideCard />}
      </div>
    </div>
  );
};

export default Main;
