import gql from 'graphql-tag';

export const HELLO = gql`
  query HELLO {
    hello
  }
`;

export const ME = gql`
  query ME {
    me
  }
`;

export const GET_BUSINESS_BY_PATHNAME = gql`
  query GET_BUSINESS_BY_PATHNAME($pathname: String!) {
    getBusinessByPathname(input: { pathname: $pathname }) {
      name
      menus {
        name
        url
      }
      businessId
    }
  }
`;

export const GET_BUSINESS_PATHNAME = gql`
  query GET_BUSINESS_PATHNAME {
    getBusiness {
      pathname
    }
  }
`;

export const GET_BUSINESS_NAME = gql`
  query GET_BUSINESS_NAME {
    getBusiness {
      name
    }
  }
`;

export const GET_MENU_URL = gql`
  query GET_BUSINESS_PATHNAME {
    getBusiness {
      menus {
        name
        url
      }
    }
  }
`;
